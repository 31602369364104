import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Navbar from "views/Navbar";
import Footer from "views/Footer";
import Top from "views/Top";

import "utils/fixFontAwesome";

import breakDownAllNodes from "utils/breakDownAllNodes";

import "../style/main.scss";
import agb from "../../content/assets/files/agb_feb_24.pdf";
import cookie from "../../content/assets/files/cookie-rl.pdf";
import dp from "../../content/assets/files/dp-agree.pdf";

export const query = graphql`
  query LegalQuery {
    allMarkdownRemark {
      nodes {
        frontmatter {
          brand
          anchor
          clients {
            href
            imageFileName
          }
          content
          copyright
          header
          email
          imageFileName
          jumpToAnchor
          jumpToAnchorText
          menuText
          privacyHref
          privacyText
          social {
            facebook
            github
            linkedin
            medium
            twitter
            xing
          }
          subheader
          telephone
          termsHref
          termsText
          title
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const LegalPage = ({ data }) => {
  const {
    allMarkdownRemark: { nodes },
  } = data;

  const { footerNode, navBarNode } = breakDownAllNodes(nodes);
  return (
    <>
      <Navbar
        anchors={[]}
        frontmatter={navBarNode.frontmatter}
        hardLinks={[{ name: "Zurück", to: "#" }]}
      />

      <Top
        frontmatter={{
          header: "Rechtliches",
          imageFileName: "legal-bg.jpg",
          jumpToAnchor: "Lesen",
          jumpToAnchorText: "Lesen",
        }}
      />
      <div id="Lesen">
        <section className="page-section bg-light" id="imprint">
          <div className="container">
            <h2>Impressum</h2>
            <h3>Unternehmensinformationen laut § 5 Abs. 1 E-Commerce-Gesetz</h3>
            <div className="text-muted">
              <p>
                Caroline Zündel Wertpapierdienstleistungsunternehmen
                <br />
                Unabhängige Vermögensberatung
              </p>
              <p>
                Caroline Zündel BA, CPM, EFA
                <br />
                Hainweg 8/4
                <br />
                6890 Lustenau
                <br />
                Austria
              </p>
              <p>
                Handy: <a href="tel:+43 676 9690016">+43 676 9690016</a>
                <br />
                Email: <a href="mailto:caroline.zuendel@gmx.at">caroline.zuendel@gmx.at</a>
                <br />
                Homepage: <a href="www.zuendelinvest.com">www.zuendelinvest.com</a>
              </p>
            </div>
            <h3>Kammerzugehörigkeit:</h3>
            <div className="text-muted">
              Wirtschaftskammer Vorarlberg, Fachgruppe Finanzdienstleister, Berufsgruppe der
              Vermögensberater und Wertpapierdienstleistungsunternehmen sowie der Vermittlung von
              Personalkrediten, Hypothekarkrediten
            </div>
            <h3>Berufsbezeichnungen:</h3>
            <div className="text-muted">
              Konzessioniertes Wertpapierdienstleistungsunternehmen laut Bescheid vom 12.12.2019
              gemäß §4 Abs. 1 Wertpapieraufsichtsgesetz 2018 (WAG 2018), BGBI.I Nr. 107/2017/
              Aktenzahl W00855/0001-WAW/2019
            </div>
            <h3>Geschäftsführung:</h3>
            <div className="text-muted">
              § 5 Abs 1 Z 12 BWG: Voraussetzungen für Geschäftsleiter:
              Das Wertpapierdienstleistungsunternehmen &ldquo;Caroline Zündel&rdquo; hat nur einen
              Geschäftsführer in der Form von Frau Caroline Zündel BA, CPM, EFA.
            </div>
            <h3>Umsatzsteueridentifikationsnummer</h3>
            <div className="text-muted">UST-Ident: ATU 74062756</div>
            <h3>Behörde gemäß ECG</h3>
            <div className="text-muted">
              Bezirkshauptmannschaft Dornbirn, Klaudiastraße 2, 6850 Dornbirn
            </div>
            <h3>Aufsichtsbehörden</h3>
            <div className="text-muted">
              <ul>
                <li>Bezirkshauptmannschaft Dornbirn, Klaudiastraße 2,6850 Dornbirn</li>
                <li>Finanzmarktaufsicht, Otto-Wagner-Platz 5, A-1090 Wien</li>
                <li>
                  Bundesministerium für Wissenschaft Forschung und Wirtschaft, Stubenring 1, 1010
                  Wien
                </li>
              </ul>
            </div>
            <h3>Wesentliche berufsrechtliche Vorschriften</h3>
            <div className="text-muted">
              Wertpapieraufsichtsgesetz, Handelsvertretergesetz, Börsengesetz,
              Investmentfondsgesetz, Kapitalmarktgesetz, Finanzmarktaufsichtsbehördengesetz,
              Konsumentenschutzgesetz, Telekommunikationsgesetz, Bankwesengesetz
            </div>
            <h3>FMA Finanzmarktaufsicht</h3>
            <div className="text-muted">
              <a href="www.fma.gv.at">www.fma.gv.at</a>
              <br />
              Die jeweils aktuelle Version der Rechtsvorschriften ist über die kostenlos zugängliche
              Website des RIS des Bundeskanzleramtes 
              <a href="www.ris.bka.gv.at">www.ris.bka.gv.at</a> abrufbar.
            </div>
            <h3>WKÖ Vorarlberger Finanzdienstleister</h3>
            <a href="www.vorarlberger-finanzdienstleister.at">
              www.vorarlberger-finanzdienstleister.at
            </a>
            <h3>Verein für Konsumenteninformation</h3>
            <a href="www.vki.at">www.vki.at</a>
            <h3>Haftungsausschluss</h3>
            <div className="text-muted">
              Wir, Caroline Zündel BA, CPM, EFA, Wertpapierdienstleistungsunternehmen
              sind bestrebt, die veröffentlichten Informationen immer
              aktuell und richtig zu halten. Wir können jedoch keine wie immer lautende Haftung oder
              Garantie für die Aktualität, Richtigkeit sowie Vollständigkeit der Inhalte übernehmen.
              Gleiches gilt auch für alle anderen Webseiten, auf die mittels Hyperlink verwiesen
              wird. Aus diesem Grunde können wir für keinerlei Schäden, aus welchen Gründen auch
              immer, die sich aus der Nutzung der Inhalte ergeben können, haftbar gemacht werden.
              Mit dieser Homepage wollen wir nur Informationen für Interessenten und Kunden
              vermitteln.
              <p>
                <em>
                  Der Inhalt und die Struktur dieser Webseiten sind von uns urheberrechtlich
                  geschützt. Die Vervielfältigung von Informationen oder Daten, insbesondere die
                  Verwendung von Texten, Textteilen oder Bildmaterial, bedarf unserer vorherigen,
                  ausdrücklichen und schriftlichen Zustimmung.
                </em>
              </p>
            </div>
            <h3>Geschlechtsneutrale Formulierung</h3>
            <div className="text-muted">
              Aus Gründen der einfacheren Lesbarkeit wird auf die geschlechtsspezifische
              Differenzierung verzichtet. Entsprechende Begriffe gelten im Sinne der
              Gleichbehandlung grundsätzlich für beide Geschlechter. Anfragen können an die unter
              Kontakt angeführten Kommunikationsstellen gerichtet werden.
            </div>
          </div>
        </section>

          <section className="page-section" id="partner">
          <div className="container">
            <h2>Kooperationspartner</h2>
            <h4>Dadat</h4>
            <ul>
              <li>
                <a href="https://www.dad.at/AGB">
                  AGB
                </a>
              </li>
              <li>
                <a href=" https://www.dad.at/Rechtliche-Hinweise">
                  Mifid II und WAG
                </a>
              </li>
            </ul>
            <h4>Easybank</h4>
            <ul>
              <li>
                <a href="https://www.easybank.at/easybank/agb">
                  AGB
                </a>
              </li>
              <li>
                <a href="https://www.easybank.at/easybank/ueber-uns/mifid-ii-mifir">
                  MiFID II Kundeninformation und Best Execution
                </a>
              </li>
            </ul>
          </div>
          <div className="container" id="Links">
            <h2>Links</h2>
            <ul>
              <li>
                <a href={agb}>AGB - Zündel Invest</a>
              </li>
              <li>
                <a href={cookie}>Cookie Richtlinie - Zündel Invest</a>
              </li>
              <li>
                <a href={dp}>Datenschutzrichtlinie - Zündel Invest</a>
              </li>
            </ul>
          </div>
        </section>

      </div>
      <Footer frontmatter={footerNode.frontmatter} />
    </>
  );
};

LegalPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LegalPage;
